export function areaMap() {
	const map = document.querySelector('.area-map')

	if (typeof (map) != 'undefined' && map != null) {

		const areas = document.querySelectorAll('.area')
		const areasUrl = SITE_URL + '/lagenheter-och-omraden/'

		const permalinks = []
		permalinks.push('kv-glasmastaren', 'kv-valten', 'kv-tradgarden-18', 'kv-tradgarden-3', 'kv-traktorn-2', 'tallen', 'kv-algen', 'cederterrassen-2')

		if (typeof (areas) != 'undefined' && areas != null) {
			areas.forEach(area => {
				area.addEventListener('click', () => {
					const areaId = area.getAttribute('data-id')
					const areaUrl = areasUrl + permalinks[areaId]

					console.log(areaUrl)

					window.open(areaUrl)
				})
			})
		}
	}

	const mapBoden = document.querySelector('.area-map-boden')

	if (typeof (mapBoden) != 'undefined' && mapBoden != null) {

		const areasBoden = document.querySelectorAll('.area-boden')
		const areasUrlBoden = SITE_URL + '/lagenheter-och-omraden/'

		const permalinksBoden = []
		permalinksBoden.push('kv-bjorknasvallen')

		if (typeof (areasBoden) != 'undefined' && areasBoden != null) {
			areasBoden.forEach(area => {
				area.addEventListener('click', () => {
					const areaId = area.getAttribute('data-id')
					const areaUrl = areasUrlBoden + permalinksBoden[areaId]

					window.open(areaUrl)
				})
			})
		}
	}
}